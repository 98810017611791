import { useEffect, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { SliderComponent } from '../../components';
import { HashLink } from 'react-router-hash-link';
import {
  Arrow,
  OpenAccountRed,
  ApplyMortgageRed,
  GetEquityRed,
  CardServicesRed,
  FixedRed,
  UpgradeAccountRed,
  PhoneRed
} from '../../assets/img';
import { NavButton } from '../../styles';
import {
  personalProducts,
  quickLinks,
} from '../../components/main';
import { Link } from 'react-router-dom';
// import { Footer } from '../../components/footer';
import api from '../../services/api';
import {  SliderContext } from '../../contexts/slider'
import {  WireMobileContext } from '../../contexts/hero'
import { PressLandingContext, YoutubeLinksContext, BlogsContext } from '../../contexts/blog';
import { 
  BLOGS_FAILURE, 
  BLOGS_REQUEST, 
  BLOGS_SUCCESS, 
  BLOG_LINKS_FAILURE, 
  BLOG_LINKS_REQUEST, 
  BLOG_LINKS_SUCCESS, 
  HERO_FAILURE, 
  HERO_REQUEST, 
  HERO_SUCCESS, 
  PRESS_LANDING_FAILURE, 
  PRESS_LANDING_REQUEST, 
  PRESS_LANDING_SUCCESS, 
  SLIDER_FAILURE, 
  SLIDER_REQUEST, 
  SLIDER_SUCCESS 
} from '../../constants';
import { HelpersComponent, ChatComponent } from '../../components';
import { Screen } from '../../styles'


// const Navigation = NavigationBar.Navigation;
const Sliders = SliderComponent.Sliders;
const ComponentLoader = HelpersComponent.dashboardLoader;
const CookieModal = HelpersComponent.Modal;
const Landing = () => {

  const [mobile, setMobile] = useState(false)
  const [pressMobile, setPressMobile] = useState(false)
  const [showCookieModal, setShowCookieModal] = useState(false)
  
  const { dispatch, state } = useContext(SliderContext)
  // const { dispatch: mobileDispatch, state: mobileState } = useContext(MobileSliderContext)
  const { dispatch: pressDispatch, state: pressState } = useContext(PressLandingContext)
  const { dispatch: youtubeDispatch } = useContext(YoutubeLinksContext)
  const { dispatch: blogDispatch, state: blogState } = useContext(BlogsContext)
  const { dispatch: wireDispatch, state: wireState } = useContext(WireMobileContext)
  const mount = useRef()
  const responsive = () => {
    return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
  }
  const pressResponsive = () => {
    return window.innerWidth < 768 ? setPressMobile(true) : setPressMobile(false)
  }

  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
    responsive()
    pressResponsive()

    window.addEventListener('resize', () => responsive())
    window.addEventListener('resize', () => pressResponsive())

    return () => {
    window.removeEventListener('resize', () => responsive())
    window.removeEventListener('resize', () => pressResponsive())
    }

  }, []);

  // get sliders data
  const slidersImage = async () => {
    try {
      if(state.data.length < 1){
        dispatch({ type: SLIDER_REQUEST })
        const result = await api.req('/api/document/sliders')
        dispatch({
          type: SLIDER_SUCCESS,
          payload: JSON.parse(result.details.data)
        })
      }
  } catch (error) {
    dispatch({
      type: SLIDER_FAILURE,
      payload: error.response && error.response.result.responseMessage 
      ? error.response.result.responseMessage : error.responseMessage
    })
  }
}

  // get wire mobile image
  const wireMobile = async () => {
    try {
      if(wireState.data.length < 1){
        wireDispatch({ type: HERO_REQUEST })
        const result = await api.req('/api/document/hero/wire-mobile')
        wireDispatch({
          type: HERO_SUCCESS,
          payload: JSON.parse(result.details.data)
        })
      }
  } catch (error) {
    wireDispatch({
      type: HERO_FAILURE,
      payload: error.response && error.response.result.responseMessage 
      ? error.response.result.responseMessage : error.responseMessage
    })
  }
}

//Blog
const getBlogs = async () => {
  try {
    if(blogState.data.length < 1){
      blogDispatch({ type: BLOGS_REQUEST})
      const result = await api.req('/api/document/blogs')
      blogDispatch({
        type: BLOGS_SUCCESS,
        payload: JSON.parse(result.details.data)
      }) 
    }
    
  } catch (error) {
    blogDispatch({
      type: BLOGS_FAILURE,
      payload: error.response && error.response.result.responseMessage 
      ? error.response.result.responseMessage : error.responseMessage
    }) 
  }
}


 // get press articles
 const getPress = async () => {
try {
  if(pressState.data.length < 1){
    pressDispatch({ type: PRESS_LANDING_REQUEST})
    const result = await api.req('/api/document/press')
    pressDispatch({
      type: PRESS_LANDING_SUCCESS,
      payload: JSON.parse(result.details.data)
    }) 
  }

  
} catch (error) {
  pressDispatch({
    type: PRESS_LANDING_FAILURE,
    payload: error.response && error.response.result.responseMessage 
    ? error.response.result.responseMessage : error.responseMessage
  }) 
}
}

 // get youtube api
 const getApi = async () => {
  try {
    if(state.data.length < 1){
      youtubeDispatch({ type: BLOG_LINKS_REQUEST })
      const result = await api.req('/api/document/youtube')
      youtubeDispatch({
        type: BLOG_LINKS_SUCCESS,
        payload: JSON.parse(result.details.data)
      })
      localStorage.setItem('youtube', result.details.data)
    }
} catch (error) {
  youtubeDispatch({
    type: BLOG_LINKS_FAILURE,
    payload: error.response && error.response.result.responseMessage 
    ? error.response.result.responseMessage : error.responseMessage
  })
}
}

const cookieButton = () => {
  localStorage.setItem('cookie', 'true')
  setShowCookieModal(false)
}
  useEffect(() => {
    mount.current = true;

    // cookies modal
    const modalTimer = setTimeout(() => {
      if(localStorage.getItem('cookie')){
        setShowCookieModal(false)
      } else {
        setShowCookieModal(true)
      }
    }, 10000)
  slidersImage()
  wireMobile()
  getPress()
  getApi()
  getBlogs()
  
  return () => {
    clearTimeout(modalTimer)
    mount.current = false;
  }
}, [])

const desktopView = () => {
  return (
    // <FlexBox>
    <Content
      className='interest'
      // data-aos='fade-right'
      // data-aos-offset='100'
    >
      <h3>
        You might be <br />
        <span>interested</span> in...
      </h3>
      <p>
        At Abbey, we believe in providing value to our
        customers through banking opportunities and
        information. Check out the Abbey Blog to learn
        more about Finance related topics.
      </p>
      <Link to={'https://medium.com/@abbeymortgagebank'} style={{ textDecoration: 'none'}} target='_blank' rel='noreferrer'>
      <InterestButton>See all articles</InterestButton>
      </Link>
    </Content>
  // </FlexBox>
  )
}

const mobileView = () => {
  return (
            <Content className='interest-two'>
            <h3>
                You might be <br />
                <span>interested</span> in...
              </h3>
              <p>
                At Abbey, we believe in providing value to our
                customers through banking opportunities and
                information. Check out the Abbey Blog to learn
                more about Finance related topics.
              </p>
              <BlogContainer>
              {
                    blogState.data.slice(0, 5).reverse().map((item, i) => (
                      // <Medium item={item} key={i} />
                      <BlogCard key={i}>
                        <Image src={item.imageUrl} alt='blog' className='press' />
                        <CardContent>
                          <BlogHeader>{item.title}</BlogHeader>
                          <CardAction>
                          <BlogLink to={item.link} target='_blank' rel='noreferrer'>Read More</BlogLink>
                          </CardAction>
                        </CardContent>
                      </BlogCard>
                    ))
                  }
              </BlogContainer>
              <div style={{ display: 'flex', justifyContent: 'center'}}>
              <Link to={'https://medium.com/@abbeymortgagebank'} style={{ textDecoration: 'none'}} target='_blank' rel='noreferrer'>
              <InterestButton>See all articles</InterestButton>
              </Link>
              </div>
            </Content>
  )
}
return (
  <Box>
      {/* Nav */}
      {/* <Navigation r={r} /> */}
      {/* End of Nav */}
      {
        state.loading ? <ComponentLoader /> : (
          <>
          <Section className='slider'>
        <Sliders state={state.data} wireState={wireState.data} />
        <Container className='quick'>
          <Quick data-aos='zoom-in-up'>
            <h2>Quick Links</h2>
            <FlexBox className='quickLinks'>
              {quickLinks.map(({ name, path, icon, className }) => (
                <QuickLinks to={path}>
                  <Image src={icon} alt='quickLinks' className={className} />
                  <CardLinks>{name}</CardLinks>
                </QuickLinks>
              ))}
            </FlexBox>
          </Quick>
        </Container>
      </Section>

      <Section className='product'>
        {personalProducts.map(({ title, description, icon, path }) => (
          <ProductCard key={title} data-aos='fade-up' data-aos-offset='150'>
            <Image src={icon} alt='icons' className='icons' />
            {/* <BlackBox /> */}
            <ProductCardBody>
              <h2>{title}</h2>
              <p>{description}</p>
              {/* <ProductLink href={path}> */}
              <HashLink to={path} smooth>
                <ProductButton className='product'>Learn More</ProductButton>
              </HashLink>
            </ProductCardBody>
          </ProductCard>
        ))}
      </Section>
      {/* <Container> */}
        {/* Watch and Learn */}
        <Section className='video'>
        <Container className='video'>
          {/* <FlexBox className='video'> */}
            {
              mobile ? (
                <FlexBox className='video'>
            <Content className='watch-top'>
            <h4> Watch &amp; <span>Learn</span></h4>
            </Content>
            <Video>
              <VideoCard>
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/iSExY87og18" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </VideoCard>
            </Video>  
            </FlexBox>
              ) : (
                <FlexBox className='video'>
                <Video data-aos='fade-right' data-aos-offset='150'>
                <VideoCard>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/iSExY87og18" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </VideoCard>
              </Video> 
            <Content
              className='watch'
              data-aos='fade-up'
              data-aos-offset='150'
            >
              <h3>
                Watch <br />
                &amp; <span>Learn</span>
              </h3>
              <WatchLink to={'https://www.youtube.com/@abbeymortgagebank9254'} target='_blank' rel='noreferrer'>See more videos</WatchLink>
            </Content> 
            </FlexBox>
              )
            }
          {/* </FlexBox> */}
          </Container>
        </Section>
        {/* End of watch and learn */}

        {/* New and Press */}
        <Section className='press' id='mortgage-loan'>
          <Container className='press'>

          <Content className='press'>
            {
              mobile ? <h3>
              News &amp; Press Releases
            </h3> :  <h3>
              News &amp; <br /> Press Releases
            </h3> 
            }
          </Content>
          {/* <Content className='press-top'> */}
          {/* </Content> */}
          <PressContainer data-aos='fade-up' data-aos-offset='150'>
            {/* <Investor press={press} /> */}
            {
              pressState.data.slice(0, 3).reverse().map((item, i) => (
                <Card key={i}>
                <Image src={item.imageUrl} alt='press' className='press' />
                <CardContent>
                  <CardHeader>{item.title}</CardHeader>
                </CardContent>
                <CardAction>
                  <CardLink to={item.link} target='_blank' rel='noreferrer'>Read More</CardLink>
                </CardAction>
              </Card>
              ))
            }
          </PressContainer>
          </Container>
        </Section>
        {/* End of New and Press */}

        {/* Interest */}
        <Section>
          <Container className='interest'>
          {
            pressMobile ? mobileView() : desktopView()
          }
          </Container>
          
        </Section>
        {/* End of Interest */}
      {/* </Container> */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
          </>
        )
      }
      {/* <ChatComponent.Hoducc /> */}
      <CookieModal showModal={showCookieModal} cookieButton={cookieButton} />
    </Box>
  );
};

const Box = styled.div`
  max-width: 100%;
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
  ${Screen.galaxyFold`
  overflow-x: hidden!important
  `}
`;
const Container = styled.div`
  width: 85%;
  margin: 0 auto;
 
  &.quick {
    border: 5px solid #f3f3f3;
    margin-top: 5rem;
    padding: 5rem 0;

    ${Screen.pad`
    width: 95%;
    margin-top: 3rem;
    padding: 2rem 1rem;
    `}
    ${Screen.pc`
    width: 95%;
    margin-top: 3rem;
    padding: 2rem 1rem;
    border: none;
    `}
    ${Screen.tablet`
    margin-top: 3rem;
    padding: 2rem 0;
    padding-left: 1rem;
    border: none;
    `}
    ${Screen.miniTablet`
    margin-top: 3rem;
    margin-left: 1rem;
    padding: 2rem 0;
    border: none;
    `}
    ${Screen.galaxyFold`
    width: 100%;
    `}
  }

  &.video {
    ${Screen.mediumpc`
    width: 90%!important;
    margin: 0!important;
    `}
    ${Screen.smallpc`
    width: 90%!important;
    margin: 0!important;
    `}
     ${Screen.surfaceDuo`
    width: 100%!important;
    margin: 0!important;
    `}
    ${Screen.mediumPhone`
    width: 100%!important;
    margin: 0!important;
    `}
    ${Screen.phone`
    width: 100%!important;
    margin: 0!important;
    `}
    ${Screen.smallPhone`
    width: 100%!important;
    margin: 0!important;
    `}
  }

  &.press {
    ${Screen.smallPhone`
    width: 100%!important;
    `}
  }
`;
const Section = styled.section`
  width: 100%;
  &.product {
    min-height: 100vh;
    background-image: url(${Arrow});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem;

    ${Screen.miniLargeScreen`
    min-height: 90vh!important;
    padding: 0;
    `}
    ${Screen.pad`
    min-height: 100vh!important;
    padding: 0;
    `}
    ${Screen.mediumpc`
    min-height: 40vh!important;
    padding: 0;
    `}
    ${Screen.smallpc`
    min-height: 40vh!important;
    padding: 0;
    `}
    ${Screen.tablet`
    min-height: 50vh!important;
    padding: 0;
    `}
    ${Screen.surfaceDuo`
    min-height: 100vh!important;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 0 5rem;
    background-image: none;
    `}
    ${Screen.mediumPhone`
    flex-direction: column;
    justify-content: center;
    padding: 3rem 0 5rem;
    background-image: none;
    `}
    ${Screen.phone`
    flex-direction: column;
    justify-content: center;
    padding: 3rem 0 5rem;
    background-image: none;
    `}
    ${Screen.smallPhone`
    flex-direction: column;
    justify-content: center;
    padding: 3rem 0 5rem;
    background-image: none;
    `}

    /* ${Screen.mediumpc`
    flex-direction: column;
    justify-content: center;
    padding: 3rem 0 5rem;
    background-image: none;
    `} */
  }

  &.slider {
    margin: 80px 0 0;
    ${Screen.smallpc`
    margin: 0;
    `}
  }

  &.interest {
    min-height: 70vh;
    margin-top: 5rem;
  }
  &.press {
    min-height: 100vh;
    margin-top: 5rem;

    ${Screen.miniLargeScreen`
    min-height: 75vh!important
    `}
     ${Screen.pad`
    min-height: 100vh!important
    `}
      ${Screen.pc`
    min-height: 90vh!important
    `}
    ${Screen.mediumpc`
    min-height: 45vh!important
    `}
    ${Screen.smallpc`
    min-height: 40vh!important
    `}
     ${Screen.ipadAir`
    min-height: 50vh!important
    `}
     ${Screen.tablet`
    min-height: 55vh!important
    `}
      /* ${Screen.custom`
    min-height: 70vh!important
    `} */
    /* overflow-y: hidden */
  }
  &.video {
    min-height: 50vh;
    ${Screen.mediumpc`
    min-height: 25vh
    `}
    ${Screen.smallpc`
    min-height: 20vh
    `}
  }
`;
const ProductCard = styled.div`
  width: 360px;
  height: auto;
  background-color: #f3f3f3;
  margin: -2rem auto 0;
  box-shadow: 5px 3px 5px #ccc;
  border-radius: 10px;

  ${Screen.pad`
  width: 280px
  `}
  ${Screen.mediumpc`
  width: 270px
  `}
  ${Screen.smallpc`
  width: 250px
  `}
  ${Screen.surfaceDuo`
  width: 300px!important;
  margin-top: 2rem;
  border-radius: 0;
  `}
  ${Screen.tablet`
  width: 230px;
  margin-top: 2rem;
  `}
  ${Screen.mediumPhone`
  width: 300px;
  margin-top: 2rem;
  `}
  ${Screen.phone`
  width: 300px;
  margin-top: 2rem;
  `}
  ${Screen.smallPhone`
  width: 250px!important;
  margin-top: 2rem;
  `}
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.quickLinks {
    justify-content: center;
    align-items: center;
 
     ${Screen.smallpc`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  align-items: center
  `}
   ${Screen.miniTablet`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  align-items: center
  `}
  }

  &.video {
    ${Screen.mediumpc`
     justify-content: center!important;
    `}
    ${Screen.smallpc`
    flex-direction: column;
    justify-content: center!important;
    `}


  }
`;
const Content = styled.div`
  &.interest {
    width: 40%;

    ${Screen.pad`
    width: 100%!important
    `}

    h3 {
      color: #002668;
      font-size: 2.5rem;
      font-weight: bold;
      word-spacing: 0.3rem;

      
      ${Screen.mediumpc`
      font-size: 2rem;
      word-spacing: 0;
      `}
      span {
        color: #c81e19;
      }
    }
    p {
      padding-top: 2rem;
    }
  }
  &.interest-two {
    width: 100%;
    padding-top: 2rem;
    h3 {
      color: #002668;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      span {
        color: #c81e19;
      }
    }
    p {
      padding-top: 2rem;
      text-align: center;
      font-size: .9rem
    }
  }

  &.watch {
    width: 17%;
    margin-top: -20rem;
    ${Screen.mediumpc`
    padding-left: 3rem;
    margin-top: -9rem!important;
    `}
    h3 {
      font-size: 2.5rem;
      color: #002668;
      font-weight: bold;
      padding-right: 0;

      span {
        color: #c81e19;
      }
    ${Screen.mediumpc`
    font-size: 2rem;
    `}
    }
  }
  &.watch-top {
    ${Screen.smallpc`
    margin-top: 0!important;
    h4 {
      font-size: 1.5rem;
      padding-bottom: 1rem;
      color: #002668;
      font-weight: bold;
      span {
          color: #c81e19;
        }
    }
    `}
    ${Screen.mediumpc`
    // text-align: center;
    margin-top: 0!important;
    h4 {
      font-size: 1.5rem;
      padding-bottom: 1rem;
      color: #002668;
      font-weight: bold;
      span {
          color: #c81e19;
        }
    }
    `}
  }

  &.press {
    h3 {
      font-size: 2.5rem;
      color: #002668;
      font-weight: bold;
      ${Screen.mediumpc`
      font-size: 2.2rem;
      `}
      ${Screen.smallpc`
      font-size: 2rem;
      text-align: center
      `}
      ${Screen.mediumPhone`
      font-size: 1.5rem;
      text-align: center
      `}
      ${Screen.phone`
      font-size: 1.5rem;
      text-align: center
      `}
      ${Screen.smallPhone`
      font-size: 1.5rem;
      text-align: center
      `}
    }
  }

  /* &.press-top {
    display: none;
   
    ${Screen.mediumpc`
    display: inline;
      font-size: 1.5rem;
      text-align: center;
      color: #002668;
      font-weight: bold;
    `}
  } */
  h2 {
    font-size: 6rem;
    padding-top: 4rem;
    margin-top: -2rem;
    color: #002668;
    span {
      color: #c81e19;
    }
  }
`;
const Quick = styled.div`
  h2 {
    color: #002668;
    font-size: 2.5rem;
    text-align: center;

    ${Screen.pad`
    font-size: 2rem;
    `}
    ${Screen.pc`
    font-size: 2rem;
    `}
     ${Screen.mediumpc`
    font-size: 1.8rem;
    `}
    ${Screen.smallpc`
    font-size: 1.5rem;
    `}
  }
`;
const Image = styled.img`
  &.mobiles {
    margin-top: 1rem;
  }
  &.press {
    width: 100%;
    height: 200px;
  }
  &.kidzsave,
  &.house,
  &.zoomer {
    width: 600px;
    height: 550px;
  }
  &.play {
    width: 100px;
    height: 100px;
    margin-top: 13rem;
  }
  &.icons {
    width: 2.5rem;
    height: 2.5rem;
    float: right;
    margin: 1.5rem 2rem 0 0;

    ${Screen.miniLargeScreen`
    width: 2rem;
    height: 2rem;
    `}
  }
  &.openAccount,
  &.applyMortgage,
  &.equityRelease,
  &.cardServices,
  &.fixedDeposit,
  &.upgradeAccount,
  &.contactUs {
    width: 3rem;
    height: 3rem;
    cursor: pointer;

    ${Screen.miniLargeScreen`
    width: 2.5rem;
    height: 2.5rem
    `}
  }
  &:hover {
    &.openAccount {
      content: url(${OpenAccountRed});
      width: 3rem;
      height: 3rem;
      cursor: pointer;
    }
    &.applyMortgage {
      content: url(${ApplyMortgageRed});
      width: 3rem;
      height: 3rem;
      cursor: pointer;
    }
    &.equityRelease {
      content: url(${GetEquityRed});
      width: 3rem;
      height: 3rem;
      cursor: pointer;
    }
    &.cardServices {
      content: url(${CardServicesRed});
      width: 3rem;
      height: 3rem;
      cursor: pointer;
    }
    &.fixedDeposit {
      content: url(${FixedRed});
      width: 3rem;
      height: 3rem;
      cursor: pointer;
    }
    &.upgradeAccount {
      content: url(${UpgradeAccountRed});
      width: 3rem;
      height: 3rem;
      cursor: pointer;
    }
    &.contactUs {
      content: url(${PhoneRed});
      width: 3rem;
      height: 3rem;
      cursor: pointer;
    }
  }
`;

const Button = styled(NavButton)`
  width: 8rem;
  height: 3rem;
  border-radius: 5px;
  margin-left: 0;
  margin-top: 2rem;
  font-weight: bold;
  letter-spacing: 1px;
  background-color: ${({ color }) => color};
`;

const CardLinks = styled.p`
  color: #000;
  font-weight: bold;
  font-size: 16px;
  padding-top: 1rem;
  ${Screen.pad`
  padding-top: 1rem;
  font-size: 14px;
  `}
  ${Screen.mediumpc`
  padding-top: 1rem;
  font-size: 12px;
  `}
  ${Screen.mediumPhone`
  padding-top: .5rem;
  font-size: 13px;
  `}
  ${Screen.phone`
  font-size: 12px;
  `}
  ${Screen.smallPhone`
  font-size: 10.5px;
  `}
  ${Screen.galaxyFold`
  font-size: 9.5px;
  `}
`;
const ProductCardBody = styled.div`
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2rem;

  ${Screen.miniLargeScreen`
  margin-top: 6rem;
  `}
${Screen.midScreen`
  margin-top: 4rem;
  `}

  h2 {
    padding-bottom: 1rem;
    font-weight: bold;

    ${Screen.miniLargeScreen`
    font-size: 18px
    `}
     ${Screen.mediumPhone`
     font-size: 20px
    `}
    ${Screen.phone`
    font-size: 20px
    `}
    ${Screen.smallPhone`
    font-size: 20px;
    `}
  }

  p {
    ${Screen.miniLargeScreen`
    font-size: 16px
    `}
    ${Screen.miniLargeScreen`
    font-size: 14px
    `}
  }
`;
const ProductButton = styled(Button)`
  &.product {
    height: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: #002668;
    box-shadow: 5px 3px 5px #ccc;

    ${Screen.midScreen`
    width: 6rem;
    letter-spacing: 0
    `}
  }
  &.interest {
    height: 2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    background-color: #c81e19;
    box-shadow: 5px 3px 5px #ccc;
  }
`;

const InterestButton = styled(Button)`
  width: 10rem;
  background-color: #fff;
  color: #c81e19;
  border: 3px solid #c81e19;
`;
const Video = styled.div`
  width: 70%;
  margin-right: 3rem;
  /* ${Screen.mediumpc`
  width: 80%;
  `} */
  /* ${Screen.smallPhone`
  width: 100%!important;
  `} */
  ${Screen.mediumpc`
  margin-right: 0!important;
  `}
  ${Screen.smallpc`
  width: 100%!important;
  margin-right: 0!important;
  `}
  ${Screen.tablet`
  width: 100%!important;
  margin-right: 0!important;
  `}
  ${Screen.surfaceDuo`
  width: 100%!important;
  margin-right: 0!important;
  `}
  ${Screen.mediumPhone`
  width: 100%!important;
  margin-right: 0!important;
  `}
  ${Screen.phone`
  width: 100%!important;
  margin-right: 0!important;
  `}
  ${Screen.smallPhone`
  width: 100%!important;
  margin-right: 0!important;
  `}
`;
const VideoCard = styled.div`
  width: 100%;
  height: 500px;

  /* ${Screen.mediumpc`
  height: 300px;
  `} */
  ${Screen.mediumpc`
  height: 300px;
  `}
  ${Screen.smallpc`
  height: 300px;
  margin-left: 3rem;
  `}
   ${Screen.miniTablet`
  margin-left: 0;
  `}
   ${Screen.surfaceDuo`
  height: 300px;
  `}
  ${Screen.mediumPhone`
  height: 300px;
  margin-left: 0!important;
  `}
  ${Screen.phone`
  height: 300px;
  `}
  ${Screen.smallPhone`
  height: 300px;
  `}

`;
const PressContainer = styled.div`
  width: 75%;
  margin-left: 10rem;
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  /* ${Screen.miniLargeScreen`
  width: 100%;
  margin-left: 0;
  align-items: center;
  `}
  ${Screen.mediumpc`
  flex-direction: column;
  `} */
  ${Screen.miniLargeScreen`
  width: 100%!important;
  margin-left: 0;
  `}
  ${Screen.pad`
  width: 100%!important;
  margin-left: 0;
  `}
  ${Screen.mediumpc`
  width: 100%!important;
  margin-left: 0;
  `}
  ${Screen.smallpc`
  width: 100%!important;
  margin-left: 0;
  `}
  ${Screen.custom`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  `}
  ${Screen.surfaceDuo`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  `}
  ${Screen.mediumPhone`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  `}
  ${Screen.phone`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  `}
  ${Screen.smallPhone`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  `}
`;
const Card = styled.div`
  width: 18.5rem;
  height: auto;
  background-color: #fff;
  box-shadow: 5px 3px 5px #ccc, -5px 3px 5px #ccc;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${Screen.miniLargeScreen`
  width: 330px
  `}
${Screen.pad`
  width: 270px
  `}
  ${Screen.mediumpc`
  width: 270px;
  margin-right: 1rem;
  `}
  ${Screen.smallpc`
  width: 350px!important;
  margin-right: 1rem;
  `}
  ${Screen.surfaceDuo`
  width: 300px!important;
  margin-top: 2rem;
  `}
  ${Screen.tablet`
  width: 230px;
  margin-top: 2rem;
  `}
   ${Screen.custom`
  width: 300px;
  margin-top: 2rem;
  border-radius: 0; 
  `}
  ${Screen.mediumPhone`
  width: 300px;
  margin-top: 2rem;
  border-radius: 0; 
  `}
  ${Screen.phone`
  width: 300px;
  margin-top: 2rem;
  `}
  ${Screen.smallPhone`
  width: 250px!important;
  margin-top: 2rem;
  margin-left: 1rem;
  `}
`;
const CardContent = styled.div`
  margin-top: 0.5rem;
  padding: 1rem 1rem 3rem;
  ${Screen.smallpc`
  padding: 1rem;
  `}
`;
const CardHeader = styled.article`
  color: #000;
  font-size: .9rem;
  font-weight: bold;

  /* ${Screen.pad`
  font-size: .rem
  `} */
`;
const CardAction = styled.div`
  padding-bottom: 1rem;
`;
const CardLink = styled(Link)`
  color: red;
  font-weight: bold;
  font-size: 0.9rem;
  margin: 0 0 2rem 1rem;

  ${Screen.pad`
  font-size: 0.8rem
  `}
  ${Screen.mediumpc`
  font-size: 0.7rem
  `}
`;
const QuickLinks = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  margin-top: 3rem;
  text-decoration: none;
`;
const WatchLink = styled(Link)`
  color: #002668 !important;
  font-weight: bold;
  font-size: 0.9rem;
  padding-left: 2rem;
  &.active,
  &.visited {
    color: #002668 !important;
  }

  ${Screen.miniLargeScreen`
  padding-left: 0
  `}
`;
const BlogContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 2rem;
`;
const BlogCard = styled.div`
width: 300px;
height: auto;
background-color: #fff;
box-shadow: 5px 3px 5px #ccc, -5px 3px 5px #ccc;
cursor: pointer;
display: flex;
flex-direction: column;
justify-content: space-between;
margin-bottom: 2rem;
${Screen.smallPhone`
width: 250px;
`}
`;
const BlogHeader = styled.h6`
color: #000;
font-size: .9rem;
`;
const BlogLink = styled.h6`
  color: red;
  font-weight: bold;
  font-size: 0.9rem;
  padding-top: 2rem;
  /* text-align: left; */
  /* margin: 0 0 rem 1rem; */
`
export default Landing;
