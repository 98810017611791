import { useEffect, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  AboutGroup,
  CareersImg,
  EngageOne,
  CareersMobile,
  EngageMobile
  // SearchImg,
} from '../../assets/img';
import { recruitmentItem } from '../../components/main';
import { Footer } from '../../components/footer';
import { 
  SliderComponent, 
  HelpersComponent,
  // NavigationBar 
} from '../../components';
import { SLIDER_FAILURE, SLIDER_REQUEST, SLIDER_SUCCESS } from '../../constants';
import api from '../../services/api';
import { GalleryContext } from '../../contexts/gallery'
import { Screen } from '../../styles'


const { ImageGallery } = SliderComponent
// const Navigation = NavigationBar.Navigation; 
const ComponentLoader = HelpersComponent.dashboardLoader
const Careers = () => {
  const mount = useRef()
  const { dispatch, state } = useContext(GalleryContext)
  const [mobile, setMobile] = useState(false)

  const responsive = () => {
    return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
  }

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);

  // get sliders data
  useEffect(() => {
    mount.current = true;
    const galleryImages = async () => {
      try {
        if(state.data.length < 1){
          dispatch({ type: SLIDER_REQUEST })
          const result = await api.req('/api/document/gallery')
          dispatch({
            type: SLIDER_SUCCESS,
            payload: JSON.parse(result.details.data)
          })
        }
      } catch (error) {
        dispatch({
          type: SLIDER_FAILURE,
          payload: error.response && error.response.result.responseMessage 
          ? error.response.result.responseMessage : error.responseMessage
        })
      }
    }
    galleryImages()
    
    return () => {
      mount.current = false;
    }
  }, [])
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}

      {
        state.loading ? <ComponentLoader /> : (
          <>
          {/* Hero Section */}
          <Section className='hero'>
            <Header className='hero'>Careers</Header>
          </Section>
          {/* End of Hero Section */}
    
          {/* Join Abbey */}
          <Section className='join'>
            <Container className='join'>
              {
                mobile ? (
                  <FlexBox className='join'>
                    <MobileHeader>
                    <Image src={EngageMobile} alt='join' className='join' />
                    <Header className='join'>
                    Why <span>join</span> Abbey Mortgage Bank?
                  </Header>
                </MobileHeader>
                <Content className='join'>
                    <p>
                      At Abbey we are motivated to build dreams, we offer various
                      opportunities for those looking for an exciting career where
                      they can make an impact, at work and beyond.
                    </p>
                    <p style={{ paddingTop: '1rem' }}>
                      You will discover your strengths and areas of interest leading
                      to a fulfilling career, whether you are a student, graduate or
                      an experienced career seeker looking for growth and
                      repositioning.
                    </p>
                    <p style={{ paddingTop: '1rem' }}>
                      We'll provide the tools, support, and training to help set you
                      up for success. You’ll have many opportunities to learn on the
                      job, gain experience and find the career that suits you.
                    </p>
                  </Content>
                  </FlexBox>
                ) : (
              <FlexBox className='join'>
                <Content className='join'>
                  <Header className='join'>
                    Why <span>join</span> Abbey Mortgage Bank?
                  </Header>
                  <Content className='join'>
                    <p style={{ paddingTop: '1rem' }}>
                      At Abbey we are motivated to build dreams, we offer various
                      opportunities for those looking for an exciting career where
                      they can make an impact, at work and beyond.
                    </p>
                    <p style={{ paddingTop: '2rem' }}>
                      You will discover your strengths and areas of interest leading
                      to a fulfilling career, whether you are a student, graduate or
                      an experienced career seeker looking for growth and
                      repositioning.
                    </p>
                    <p style={{ paddingTop: '2rem' }}>
                      We'll provide the tools, support, and training to help set you
                      up for success. You’ll have many opportunities to learn on the
                      job, gain experience and find the career that suits you.
                    </p>
                  </Content>
                </Content>
                <Image src={EngageOne} alt='join' className='join' />
              </FlexBox>
                )
              }
            </Container>
          </Section>
          {/* End of Join Abbey */}
    
          {/* Diversity */}
          <Section className='diversity'>
            <Container>
              {/* {
                mobile ? (
                  <h1>Working on it...</h1>
                ): (
                  <>
                   */}
              <Header className='diversity'>Diversity and Inclusion</Header>
              <Content className='diversity'>
                <p>
                  Abbey is a proud equal opportunity employer. We are committed to
                  providing equal employment opportunities for our people regardless
                  of ethnicity, sex, race, religion, educational qualification,
                  culture, and disability.
                </p>
                <p>
                  At Abbey, we have not only created an organizational culture that
                  is committed towards enhancing the lives of our employees but we
                  also value and respect everyone’s uniqueness and differences and
                  encourage our employees to make their full contribution towards
                  achieving the business goals.
                </p>
                <p>
                  We have zero tolerance for discriminatory employment practices and
                  ensure that we create an equitable environment where our people
                  perceive fairness.
                </p>
    
                <p>
                  Finally, we are a hardworking and fun loving bunch…. Our pictures
                  tell our story
                </p>
              </Content>
                  {/* </>
                )
              } */}
            </Container>
          </Section>
          {/* End of diversity */}
    
          {/* Lifestyle */}
          <Section className='lifestyle'>
            <Container className='lifestyle'>
                  <Header className='lifestyle'>
                    The Abbey <span>Lifestyle</span>
                  </Header>
                  <ImageGallery state={state.data} />
            </Container>
          </Section>
          {/* End of Lifestyle */}
    
          {/* Recruitment */}
          <Section className='recruitment'>
            <Container className='recruitment'>
              {/* {
                mobile ? (
                  <h1>Working on it...</h1>
                ): (
                  <> */}
              <Header className='recruitment'>The Recruitment Process</Header>
              <p>
                Abbey’s application procedures vary according to the role you are
                seeking and the location where you are hoping to work. This would
                typically include the stages outlined below, depending on whether
                you are applying for a technical role or a student and graduate
                role.
              </p>
              <CardContainer>
                {recruitmentItem.map(({ number, header, content }) => (
                  <Card>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <CardBox>{number}</CardBox>
                    </div>
                    <CardHeader>{header}</CardHeader>
                    <CardBody>{content}</CardBody>
                  </Card>
                ))}
              </CardContainer>  
                  {/* </>
                )
              } */}
            </Container>
          </Section>
          {/* End of Recruitment */}
    
          {/* Dream Team */}
          <Section className='search'>
            <Container className='search'>
              {/* {
                mobile ? (
                  <h1>Working on it...</h1>
                ): (
                  <> */}
                  
                  <Header className='search'>
                    Join the <span>Dream</span> Team
                  </Header>
                  <p>
                    Want to work and learn alongside brilliant minds? Want to be part of
                    an amazing team? Want to make an impact and feel proud of the work
                    you do and make the business proud? Then, join us! To view{' '}
                    <span>available</span> jobs,{' '}
                    {/* <SearchLink to='#'>click here</SearchLink> */}
                  </p>
                  {/* <Search>
                    <Image src={SearchImg} alt='search' className='search' />
                    <input
                      type='text'
                      placeholder='Search for a Job title, Department or location'
                    />
                  </Search> */}
                  {/* </>
                )
              } */}
            </Container>
          </Section>
          {/* End of Dream Team */}
          {/* Footer */}
          {/* <Footer /> */}
          {/* End of footer */}
          </>
        )
      }

    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #ffffff;
`;
const Container = styled.div`
  width: 80%;
  margin: 0 auto;

  &.join {
    ${Screen.pad`
    width: 90%;
    `}
    ${Screen.mediumpc`
    width: 90%;
    `}
    ${Screen.smallpc`
    width: 80%;
    `}
    ${Screen.surfaceDuo`
    width: 90%;
    `}
    ${Screen.mediumPhone`
    width: 90%;
    `}
    ${Screen.phone`
    width: 90%;
    `}
    ${Screen.smallPhone`
    width: 90%;
 
    `}
  }

  &.lifestyle {
    ${Screen.surfaceDuo`
    width: 90%;
    `}
    ${Screen.smallPhone`
    width: 90%;
    `}
  }

  &.recruitment {
    ${Screen.mediumpc`
    width: 90%;
    p {
      font-size: .9rem
    }
    `}
    ${Screen.smallpc`
    width: 90%;
    p {
      font-size: .9rem
    }
    `}
    ${Screen.tablet`
    width: 80%;
    `}
    ${Screen.mediumPhone`
    font-size: .9rem;
    text-align: center;
    width: 90%
    `}
    ${Screen.phone`
    font-size: .9rem;
    text-align: center;
    p {
      font-size: .9rem!important;
    }
    `}
    ${Screen.smallPhone`
    font-size: .8rem;
    text-align: center;
    p {
      font-size: .8rem!important;
    }
    `}
    ${Screen.galaxyFold`
    font-size: .8rem;
    text-align: center;
    `}
  }
  /* &.search {
    text-align: center
  } */
`;

const Image = styled.img`
  &.join {
    width: 400px;
    height: 400px;
    ${Screen.miniLargeScreen`
    width: 350px;
    height: 350px;
    margin-top: 7rem;
    `}
    ${Screen.pad`
    width: 350px;
    height: 350px;
    margin-top: 5rem;
    `}
    ${Screen.mediumpc`
    width: 300px;
    height:300px;
    margin-top: 5rem
    `}
    ${Screen.smallpc`
    width: 250px;
    height:255px
    `}
     ${Screen.surfaceDuo`
    width: 200px;
    height: 205px
    `}
    ${Screen.mediumPhone`
    width: 160px;
    height: 160px
    `}
    ${Screen.smallPhone`
    width: 130px;
    height: 170px
    `}
  }
  &.sustainability {
    width: 80%;
  }
  &.search {
    height: 25px;
    margin-right: 1rem;
  }
`;
const Section = styled.section`
  width: 100%;
  &.hero {
    min-height: 50vh;
    background-image: url(${CareersImg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 20vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.tablet`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.smallPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
  }
  &.join {
    min-height: 70vh;
    ${Screen.mediumpc`
    min-height: 40vh
    `}
    ${Screen.smallpc`
    min-height: 60vh
    `}
    ${Screen.surfaceDuo`
    min-height: 70vh
    `}
  }
  &.diversity {
    min-height: 60vh;
    background-color: #faf9f9;
    padding-top: 3rem;
    ${Screen.mediumpc`
    min-height: 40vh
    `}
    ${Screen.smallpc`
    min-height: 50vh
    `}
    ${Screen.ipadAir`
    min-height: 60vh
    `}
  }
  &.lifestyle {
    min-height: 100vh;
    padding-top: 3rem;
    ${Screen.pad`
    min-height: 160vh
    `}
    ${Screen.mediumpc`
    min-height: 90vh
    `}
    ${Screen.smallpc`
    min-height: 70vh
    `}
    ${Screen.tablet`
    min-height: 80vh
    `}
    ${Screen.surfaceDuo`
    min-height: 100vh
    `}
    ${Screen.mediumPhone`
    min-height: 90vh
    `}
    ${Screen.phone`
    min-height: 120vh
    `}
    ${Screen.smallPhone`
    min-height: 120vh
    `}
    ${Screen.galaxyFold`
    min-height: 130vh
    `}
  }
  &.recruitment {
    min-height: 80vh;
    background-image: url(${AboutGroup});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 5rem;
    ${Screen.pad`
    min-height: 100vh;
    `}
    ${Screen.mediumpc`
    padding-top: 5rem;
    min-height: 50vh
    `}
    ${Screen.smallpc`
    padding-top: 5rem;
    min-height: 50vh
    `}
    ${Screen.tablet`
    padding-top: 5rem;
    min-height: 100vh;
    `}
    ${Screen.galaxyFold`
    padding-top: 3rem;
    min-height: 100vh;
    `}
  }
  &.search {
    padding-top: 5rem;
    ${Screen.tablet`
    padding-top: 3rem
    `}
    ${Screen.smallPhone`
    padding-top: 3rem
    `}

    p {
      color: #002668;
      padding-top: 1rem;
      ${Screen.surfaceDuo`
      font-size: .9rem
      `}
      ${Screen.mediumPhone`
      font-size: .9rem
      `}
      ${Screen.phone`
      font-size: .9rem
      `}
      ${Screen.smallPhone`
      font-size: .8rem
      `}
      span {
        font-weight: bold;
      }
    }
  }
`;
const Header = styled.h1`
  &.hero {
    color: #fff;
    font-size: 3rem;
    text-align: right;
    padding-top: 10rem;
    padding-right: 4rem;
    text-shadow: 10px 5px 5px #666;

    ${Screen.smallpc`
    padding-top: 12rem
    `}
     ${Screen.tablet`
    font-size: 3rem;
    padding-right: 3rem;
    padding-top: 10rem;
    `}
     ${Screen.surfaceDuo`
    font-size: 30px;
    padding-right: 3rem;
    padding-top: 8rem;
    `}
    ${Screen.mediumPhone`
    font-size: 26px;
    text-align: center;
    padding-right: 0;
    padding-top: 11rem;
    `}
     /* ${Screen.iphone`
    font-size: 26px;
    text-align: center;
    padding-right: 0;
    padding-top: 8rem;
    `} */
    ${Screen.phone`
    font-size: 26px;
    text-align: center;
    padding-right: 0;
    padding-top: 8rem;
    `}
    ${Screen.samsungGalaxy`
    font-size: 26px;
    text-align: center;
    padding-right: 0;
    padding-top: 9rem;
    `}
    ${Screen.smallPhone`
    font-size: 24px;
    text-align: center;
    padding-right: 0;
    padding-top: 8rem;
    `}
  }
  &.join {
    color: #002668;
    font-size: 1.5rem;

    ${Screen.miniLargeScreen`
      padding-top: 5rem;
    `}
    ${Screen.pad`
      padding-top: 5rem;
    `}
    ${Screen.mediumpc`
      font-size: 25px;
      padding-top: 0
      `}
      ${Screen.smallpc`
      font-size: 30px;
      position: absolute;
      left: 17rem;
      top: 15rem
      `}
       ${Screen.surfaceDuo`
      font-size: 24px;
      position: absolute;
      left: 13rem;
      top: 12rem
      `}
       ${Screen.mediumPhone`
      font-size: 22px;
      position: absolute;
      left: 11rem;
      top: 10rem
      `}
       ${Screen.iphone`
      font-size: 20px;
      position: absolute;
      left: 11rem;
      top: 10rem;
      `}
      ${Screen.phone`
      font-size: 20px;
      position: absolute;
      left: 10.5rem;
      top: 10rem
      `}
      ${Screen.samsungGalaxy`
      font-size: 17px;
      position: absolute;
      left: 11rem;
      top: 10rem
      `}
      ${Screen.smallPhone`
      font-size: 17px;
      position: absolute;
      left: 9rem;
      top: 10rem
      `}
      ${Screen.galaxyFold`
      font-size: 16px;
      position: absolute;
      left: 9rem;
      top: 10rem
      `}

    span {
      color: #c81e19;
    }
  }
  &.diversity {
    text-align: center;
    color: #002668;
    font-size: 1.5rem;
    ${Screen.mediumPhone`
    font-size: 20px
    `}
    ${Screen.phone`
    font-size: 18px
    `}
    ${Screen.smallPhone`
    font-size: 18px
    `}
  }
  &.lifestyle {
    color: #002668;
    font-size: 1.5rem;

    ${Screen.mediumPhone`
    text-align: center;
    font-size: 20px
    `}
    ${Screen.phone`
    font-size: 18px;
    text-align: center;
    `}
    ${Screen.smallPhone`
    font-size: 18px;
    text-align: center;
    `}

    span {
      color: #c81e19;
    }
  }
  &.recruitment {
    color: #002668;
    text-align: center;
    font-size: 1.5rem;
    padding-bottom: 1rem;

    ${Screen.mediumPhone`
    text-align: center;
    font-size: 20px
    `}
    ${Screen.phone`
    font-size: 18px;
    text-align: center;
    `}
    ${Screen.smallPhone`
    font-size: 18px;
    text-align: center;
    `}

  }
  &.search {
    color: #002668;
    font-size: 1.5rem;
    ${Screen.surfaceDuo`
    text-align: center
    `}
    ${Screen.mediumPhone`
    font-size: 20px!important;
    text-align: center
    `}
    ${Screen.phone`
    font-size: 18px!important;
    text-align: center
    `}
    ${Screen.smallPhone`
    font-size: 18px!important;
    text-align: center
    `}

    span {
      color: #c81e19;
    }
  }
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;

  &.join {
    ${Screen.mediumpc`
    margin-top: 5rem
    `}
    ${Screen.smallpc`
    margin-top: 3rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `}
    ${Screen.surfaceDuo`
    margin-top: -1rem!important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `}
    ${Screen.mediumPhone`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `}
    ${Screen.phone`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `}
    ${Screen.smallPhone`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `}
  }
`;
const Content = styled.div`
  &.join {
    width: 80%;
    padding-top: 3rem;
    P {
      font-size: 1.1rem;
      ${Screen.pad`
      font-size: 1rem;
      padding-top: 1rem;
      `}
      ${Screen.mediumpc`
      font-size: 1rem;
      padding-top: 1rem;
      `}
      ${Screen.surfaceDuo`
      font-size: .9rem;
      padding-top: 0;
      `}
      ${Screen.mediumPhone`
      font-size: .9rem
      `}
      ${Screen.phone`
      font-size: .8rem
      `}
      ${Screen.smallPhone`
      font-size: .8rem
      `}
    }
    ${Screen.pad`
    padding: 1rem 0 3rem;
    `}
    ${Screen.mediumpc`
    padding: 2rem 0 1rem;
    `}
    ${Screen.smallpc`
    width: 100%;
    padding: 3rem 0 1rem;
    `}
    ${Screen.surfaceDuo`
    width: 90%;
    padding: 2rem 0 3rem;
    `}
    ${Screen.mediumPhone`
    width: 90%;
    padding: 3rem 0 1rem;
    `}
    ${Screen.phone`
    width: 90%;
    padding: 2rem 0;
    `}
    ${Screen.smallPhone`
    width: 90%;
    padding: 2rem 0;
    `}
  }
  &.diversity {
    p {
      padding-top: 2rem;
      font-size: 1.1rem;
      ${Screen.pad`
      font-size: 1rem;
      padding-top: 2rem;
      `}
      ${Screen.surfaceDuo`
      font-size: .9rem;
      padding-top: 1rem;
      `}
      ${Screen.mediumPhone`
      font-size: .9rem;
      padding-top: 1rem
      `}
      ${Screen.phone`
      font-size: .8rem;
      padding-top: 1rem
      `}
      ${Screen.smallPhone`
      font-size: .8rem;
      padding-top: 1rem
      `}
    }
    ${Screen.pad`
    padding: 1rem 0 3rem;
    `}
    ${Screen.surfaceDuo`
    padding: 1rem 0 2rem;
    `}
    ${Screen.mediumPhone`
    padding: 1rem 0 2rem;
    `}
    ${Screen.phone`
    padding: 1rem 0 2rem;
    `}
    ${Screen.smallPhone`
    padding: 1rem 0 2rem;
    `}
  }
`;
const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  ${Screen.mediumpc`
  margin-top: 7rem;
  justify-content: space-between;
  align-items: center;
  `}
  ${Screen.smallpc`
  margin-top: 5rem;
  justify-content: space-between;
  align-items: center;
  `}
  ${Screen.tablet`
  margin-top: 5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.surfaceDuo`
  margin-top: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
`;
const Card = styled.div`
  width: 350px;
  height: 250px;
  background-color: transparent;
  border: 4px solid #c81e19;
  ${Screen.miniLargeScreen`
  height: 270px;
  margin-right: 1rem
  `}
  ${Screen.pad`
  height: 300px;
  padding-bottom: 1rem;
  margin-right: 1rem
  `}
  ${Screen.mediumpc`
  width: 250px;
  height: 250px;
  padding-bottom: 1rem;
  margin-right: 1rem
  `}
  ${Screen.smallpc`
  width: 250px;
  padding-bottom: 1rem;
  margin-right: 1rem
  `}
  ${Screen.tablet`
  width: 70%;
  height: 200px;
  margin-bottom: 2rem;
  padding-bottom: 1rem
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  height: 200px;
  margin-bottom: 2rem;
  padding-bottom: 1rem
  `}
  ${Screen.mediumPhone`
  width: 100%;
  height: 200px;
  margin-bottom: 2rem;
  padding-bottom: 1rem
  `}
  ${Screen.phone`
  width: 100%;
  height: 200px;
  margin-bottom: 2rem;
  padding-bottom: 1rem
  `}
  ${Screen.smallPhone`
  width: 100%;
  height: 200px;
  margin-bottom: 2rem;
  padding-bottom: 1rem
  `}
`;
const CardBox = styled.div`
  width: 50px;
  height: 50px;
  color: #fff;
  text-align: center;
  padding-top: 0.5rem;
  font-weight: bold;
  font-size: 1.5rem;
  background-color: #c81e19;
  margin-top: -2rem;
  box-shadow: 5px 3px 3px #ccc;
  ${Screen.tablet`
  width: max-content;
  height: max-content;
  padding: .5rem;
  font-size: 1rem;
  margin-top: -1rem;
  `}
  ${Screen.mediumPhone`
  width: max-content;
  height: max-content;
  padding: .5rem;
  font-size: 1rem;
  margin-top: -1rem;
  `}
  ${Screen.phone`
  width: max-content;
  height: max-content;
  padding: .5rem;
  font-size: 1rem;
  margin-top: -1rem;
  `}
  ${Screen.smallPhone`
  width: max-content;
  height: max-content;
  padding: .5rem;
  font-size: 1rem;
  margin-top: -1rem;
  `}
`;
const CardHeader = styled.h3`
  color: #002668;
  text-align: center;
  padding: 2rem 0;

  ${Screen.pad`
  padding: 1rem 0;
  `}
  ${Screen.mediumpc`
  padding: 1rem 0;
  `}
  ${Screen.smallpc`
  padding: .5rem 0;
  `}
  ${Screen.tablet`
  padding: 1rem 0;
  `}
  ${Screen.mediumPhone`
  padding: .5rem 0 1rem;
  `}
  ${Screen.phone`
  padding: .5rem 0 1rem;
  `}
  ${Screen.smallPhone`
  padding: .5rem 0 1rem;
  `}
`;
const CardBody = styled.p`
  color: #002668;
  padding: 0 1rem 2rem;
  text-align: center;
  ${Screen.smallpc`
  font-size: .8rem;
  padding: .5rem;
  `}
  ${Screen.smallpc`
  font-size: .8rem;
  `}
  ${Screen.mediumPhone`
  font-size: .9rem
  `}
  ${Screen.phone`
  font-size: .8rem
  `}
  ${Screen.smallPhone`
  font-size: .8rem!important
  `}
`;
const MobileHeader = styled.div`
width: 100%;
position: relative;
display:flex;
margin-top: 2rem;
`;
// const Search = styled.div`
//   width: 100%;
//   height: 3rem;
//   border-radius: 20px;
//   margin-top: 3rem;
//   display: flex;
//   justify-content: flex-start;
//   padding-left: 2rem;
//   align-items: center;
//   box-shadow: inset 5px 5px 8px #cbced1, inset -5px -5px 8px #f5f5f5;

//   input {
//     width: 100%;
//     border: none;
//     outline: none;
//     background: none;
//     font-size: 18px;
//     color: #000;
//     padding: 20px 10px 20px 5px;
//     &::placeholder {
//       margin-left: 2rem;
//       color: #000;
//     }
//   }
// `;
// const SearchLink = styled(Link)`
//   color: #c81e19;
// `;
export default Careers;
