import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { EgmDesktop } from '../../assets/img';
import { Screen } from '../../styles';
import { HashLink } from 'react-router-hash-link';

const Timer = ({ meetingLink }) => {
    const countDownDate = new Date("Sep 29 2023").getTime()
    const [countDown, setCountDown] = useState();
    const [numberInDays, setNumberInDays] = useState("")
    const mounted = useRef()

      useEffect(() => {
        mounted.current = true
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime())
        }, 1000)

        setNumberInDays(Math.floor(countDown / (1000 * 60 * 60 * 24)) + 1)

        return () => {
            clearInterval(interval)
            mounted.current = false
        }
      }, [countDown])


  return (
    <Box>
        <Image src={EgmDesktop} alt='egm' />
        <MeetingLink to={meetingLink} target='_blank' rel='noreferer'>
        <CountDownContainer>
            {numberInDays > 1 && `${numberInDays} days to go`}
            {numberInDays === 1 && `A day to go`}
            {numberInDays === 0 && "We're Live! Click here to join us on Zoom"}
            {numberInDays < 0 && ""}
        </CountDownContainer>
        </MeetingLink>
    </Box>
  )
}

const Box = styled.div`
width: 100%;

${Screen.tablet`
margin: 5rem auto;
width: 80%;
`}
${Screen.mediumPhone`
width: 100%;
margin-top: 3rem
`}
`
const CountDownContainer = styled.div`
width: 100%;
padding: 1rem;
background-color: #002668;
text-transform: uppercase;
font-size: 30px;
color: #fff;
text-align: center;
font-weight: 600;
margin-top: -4px;
cursor: pointer;

&:hover {
  background-color: #0d3375;
}

${Screen.mediumPhone`
font-size: 20px;
`}
`;

const Image = styled.img`
width: 100%;
margin-bottom: 0;
`;

const MeetingLink = styled(HashLink)`
text-decoration: none;
`

export default Timer
