import React, { useState, useEffect, useContext, useRef } from 'react'
import styled from 'styled-components'
import { RelationComponents, HelpersComponent } from '../../components'
import { Screen } from '../../styles';
import { AboutLeadership } from '../../assets/img';
// import { Footer } from '../../components/footer';
import { EgmContext } from '../../contexts/meeting';
import { MEETING_FAILURE, MEETING_REQUEST, MEETING_SUCCESS } from '../../constants';
import api from '../../services/api';

// const Navigation = NavigationBar.Navigation;
const Notice = RelationComponents.Egm;
const Timer = RelationComponents.Timer;
const ComponentLoader = HelpersComponent.componentLoader

const Meeting = () => {
    const [ mobile, setMobile ] = useState(false)
    const { dispatch, state } = useContext(EgmContext)
    const mounted = useRef()

    useEffect(() => {
        window.scrollTo(0, 0);
        const responsive = () => {
            return window.innerWidth <= 768 ? setMobile(true) : setMobile(false)
        }
        responsive()
        window.addEventListener('resize', () => responsive())

        return () => {
            window.removeEventListener('resize', () => responsive())
        }
    }, [])

    // Get meeting link
    useEffect(() => {
      mounted.current = true
      const getMeetingLink = async () => {
        try {
          if(state.data.length < 1){
            dispatch({ type: MEETING_REQUEST})
            const result = await api.req('/api/document/egm-link')
            dispatch({
              type: MEETING_SUCCESS,
              payload: JSON.parse(result.details.data)
            })
          }
        } catch (error) {
          dispatch({
            type: MEETING_FAILURE,
            payload: error.response && error.response.result.responseMessage 
            ? error.response.result.responseMessage : error.responseMessage
          })
          
        }
      }
      getMeetingLink()

      return () => {
        mounted.current = false
      }
    }, [])

  return (
    <Box>
        {/* nav */}
        {/* <Navigation /> */}
        {/* End of nav */}
        {
          state.loading ? <ComponentLoader /> : (
            <>
            {/* Hero Section */}
            <Section className='hero'>
            <Glass>
              <h2>Extra-Ordinary General Meeting</h2>
            </Glass>
            </Section>
            {/* End of Hero Section */}
    
            {/* Content */}
            <Section>
                  {
                    mobile ? (
                        // <Container>
                        <>
                        <Header>Notice of Meeting</Header>
                        <Notice  /> 
                        <Timer />              
                        </>
        
                    ):(
                        <Container>
                        <Header className='notice'>Notice of Meeting</Header>
                        <Notice />
                        <TimerContainer>
                          <Timer meetingLink={state?.data[0]?.link} /> 
                        </TimerContainer>
        
                    </Container>
                    )
                }
            </Section>
            {/* End of Content */}
    
            {/* Footer */}
            {/* <Footer /> */}
            {/* End of Footer */}
            </>
          )
        }
    </Box>
  )
}

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;

const Section = styled.div`
  width: 100%;
  /* min-height: ; */
  padding-left: 10rem;
  &.hero {
    min-height: 60vh;
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    padding: 7rem 0 0 5rem;
    ${Screen.mediumpc`
    min-height: 20vh!important;
    padding-top: 3rem;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    padding: 8rem 2rem 0;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    padding: 8rem 5rem 0;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    padding: 8rem 2rem 0;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    padding: 5rem 2rem 0;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    padding: 4rem 2rem 0;
    `}
  }

  ${Screen.tablet`
  padding-left: 0;
  `}
`;
const Header = styled.h2`
  color: #002668;
  font-size: 2rem;
  padding-top: 7rem;
  ${Screen.pad`
  font-size: 1.5rem
  `}
  ${Screen.mediumpc`
  font-size: 1.5rem;
  `}
  ${Screen.smallpc`
  font-size: 1.5rem;
  padding-left: 0;
  padding-top: 5rem
  `}
  ${Screen.tablet`
  font-size: 1.5rem;
  padding-left: 5rem;
  padding-top: 5rem
  `}
  ${Screen.surfaceDuo`
  font-size: 1.5rem;
  padding-left: 2rem;
  padding-top: 5rem
  `}
  ${Screen.mediumPhone`
  font-size: 18px;
  padding-top: 3rem;
  padding-left: 10px
  `}
  ${Screen.phone`
  font-size: 18px;
  padding-top: 3rem;
  padding-left: 10px
  `}
  ${Screen.smallPhone`
  font-size: 18px;
  padding-top: 3rem;
  padding-left: 10px
  `}

  /* &.notice {
    ${Screen.tablet`
  margin-top: 10rem
  `}
  } */
`;

const Container = styled.div`
width: 80%;
${Screen.smallPhone`
  width: 100%
  `}
`;

const TimerContainer = styled.div`
width: 100%;
margin: 3rem auto;
`;

const Glass = styled.div`
  width: 400px;
  height: 200px;
  background: rgba(255, 255, 255, 0.7);
  padding: 4rem 0 0 3rem;
  ${Screen.mediumpc`
  height: 200px;
  `}
  ${Screen.tablet`
  height: 200px;
  `}
  ${Screen.surfaceDuo`
    width: 100%;
  height: 150px;
  padding: 3rem 2rem 0;
  `}
  ${Screen.mediumPhone`
   width: 100%;
  height: 150px;
  padding: 3rem 2rem 0;
  `}
  ${Screen.phone`
  width: 100%;
  height: 150px;
  padding: 3rem 2rem 0;
  `}
  ${Screen.smallPhone`
  width: 100%;
  height: 150px;
  padding: 3rem 2rem 0;
  `}
  ${Screen.galaxyFold`
  width: 100%;
  height: 150px;
  padding: 3rem 2rem 0;
  `}

  h2 {
    font-size: 35px;
    color: #002668;
    ${Screen.mediumpc`
      font-size: 30px;
      padding-top: 0rem;
      `}
      ${Screen.tablet`
      font-size: 30px;
      padding-top: 0;
      `}
      ${Screen.surfaceDuo`
      font-size: 25px;
      text-align: center;
      `}
      ${Screen.mediumPhone`
      font-size: 22px;
      text-align: center;
      `}
      ${Screen.galaxyFold`
      font-size: 18px;
      `}
  }
`;
export default Meeting