import { useEffect, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Sustain,
  EngageOne,
  EngageTwo,
  EngageThree,
  RedCross,
  DownSyndrome,
  Nhil,
  Lagos
} from '../../assets/img';
import { HelpersComponent } from '../../components';
// import { Footer } from '../../components/footer';

import { focus } from '../../components/main';
import Carousel from '@itseasy21/react-elastic-carousel';
import '../../carousel.css';
import { Link } from 'react-router-dom';
import { SustainabilityHeroContext } from '../../contexts/hero'
import { FootprintContext } from '../../contexts/blog'
import { HERO_FAILURE, HERO_REQUEST, HERO_SUCCESS, PRESS_FAILURE, PRESS_REQUEST, PRESS_SUCCESS } from '../../constants';
import api from '../../services/api';
import { Screen } from '../../styles';


// const Navigation = NavigationBar.Navigation;
const ComponentLoader = HelpersComponent.dashboardLoader;
const Sustainability = () => {
  const mount = useRef()
  const carouselRef = useRef()
  const { dispatch, state } = useContext(SustainabilityHeroContext)
  const { dispatch: footprintDispatch, state: footprintState } = useContext(FootprintContext)
  const [mobile, setMobile] = useState(false);
  let resetTimeout;

  const responsive = () => {
    return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
  }
  useEffect(() => {
    mount.current = true
    //Scroll to top on page load
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())


    // get Hero
    const getHero = async () => {
      try {
        if(state.data.length < 1){
          dispatch({ type: HERO_REQUEST })
          const result = await api.req('/api/document/hero/sustainability')
          dispatch({
            type: HERO_SUCCESS,
            payload: JSON.parse(result.details.data)
          })
        }
      } catch (error) {
        dispatch({
          type: HERO_FAILURE,
          payload: error.response && error.response.result.responseMessage 
          ? error.response.result.responseMessage : error.responseMessage
        }) 
      }
    }
    getHero()

    return () => {
      window.removeEventListener('resize', () => responsive())
      mount.current = false
    };
  }, []);

  useEffect(() => {
    mount.current = true

    const getArticles = async () => {
      try {
        if(footprintState?.data?.length < 1){
          footprintDispatch({ type: PRESS_REQUEST })
          const result = await api.req('/api/document/footprint')
          footprintDispatch({
            type: PRESS_SUCCESS,
            payload: JSON.parse(result?.details?.data)
          })
        }
      } catch (error) {
        footprintDispatch({
          type: PRESS_FAILURE,
          payload: error.response && error.response.result.responseMessage 
          ? error.response.result.responseMessage : error.responseMessage
        })
      }
    }
    getArticles()

    return () => {
      mount.current = false
    }
  }, [])

  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}
      {
        state.loading ? <ComponentLoader /> : (
          <>
          
          {/* Hero */}
          <Section className='hero' bg={`url(${state.data[0]})`}>
            <Container className='hero'>
              <Glass>
                <Content className='glass'>
                  <h2>Sustainability</h2>
                  <p>
                    Abbey Mortgage Bank has in the last 30 years taken steps to
                    ensuring sustainability in our environment, schools and much
                    more.
                  </p>
                </Content>
              </Glass>
            </Container>
          </Section>
          {/* End of Hero */}
    
          {/* Area of Focus */}
          <Section className='focus'>
            <Container className='focus'>
            <Header className='focus'>Corporate Social Responsibility</Header>
              <SubHeader>
                Area of Focus
              </SubHeader>
              {
                mobile ? (
                  <Carousel 
                enableAutoPlay={true} 
                pagination={false}
                ref={carouselRef}
                isRTL={false}
                autoPlaySpeed={4000}
                onNextEnd={() => {
                  clearTimeout(resetTimeout);
                  resetTimeout = setTimeout(() => {
                      carouselRef?.current?.goTo(0);
                  }, 4000);
              }}>
                {focus.map(({ icon, title, content }) => (
                  <Card key={title} className='focus'>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Image src={icon} alt='icon' className='focus' />
                    </div>
                    <CardHeader>{title}</CardHeader>
                    <CardContent>
                      <p>{content}</p>
                    </CardContent>
                  </Card>
                ))}
              </Carousel>
                ) : (
                  <>
                  {/* <Header className='focus'>Corporate Social Responsibility</Header>
              <p
                style={{
                  textAlign: 'center',
                  color: '#002668',
                  paddingTop: '1rem',
                  fontSize: '1.3rem',
                }}
              >
                Area of Focus
              </p> */}
              <CardContainer>
                {focus.map(({ icon, title, content }) => (
                  <Card key={title} className='focus'>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Image src={icon} alt='icon' className='focus' />
                    </div>
                    <CardHeader>{title}</CardHeader>
                    <CardContent>
                      <p>{content}</p>
                    </CardContent>
                  </Card>
                ))}
              </CardContainer>
                  </>
                )
              }
            </Container>
          </Section>
          {/* End of Area of Focus */}
    
          {/* Employee engagement */}
          <Section className='engagement'>
            <Container className='engagement'>
              {
                mobile ? (
                  <>
                  <FlexBox className='engagement'>
                  <ImagesContainer>
                  <Image src={EngageOne} alt='engage' className='engageOne' />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Image src={EngageTwo} alt='engage' className='engageTwo' />
                    <Image src={EngageThree} alt='engage' className='engageThree' />
                  </div>
                </ImagesContainer>
                <Content className='engagement'>
                  <Header className='engagement'>Employee Engagement</Header>
                  <p>
                  As proud Abbey Ambassadors, our staff 
                  participate in initiatives from Corporate 
                  Social Responsibilities to products, events 
                  and brand promotion. Our winning team are 
                  always ready to graft and shine for Abbey
                  </p>
                  <p>
                    Both Management and Employees have taken 
                    steps to building up the community on behalf 
                    of Abbey Mortgage bank by Walking for the Environment, 
                    making donations, sharing posts on social media and so 
                    much more.
                  </p>
                </Content>
              </FlexBox>  
                  </>
                ) : (
              <FlexBox className='engagement'>
                <Content className='engagement'>
                  <Header>Employee Engagement</Header>
                  <p>
                  As proud Abbey Ambassadors, our staff 
                  participate in initiatives from Corporate 
                  Social Responsibilities to products, events 
                  and brand promotion. 
                  </p>
                  <p>Our winning team are 
                  always ready to graft and shine for Abbey</p>
                </Content>
                <ImagesContainer>
                  <Image src={EngageOne} alt='engage' className='engageOne' />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Image src={EngageTwo} alt='engage' className='engageTwo' />
                    <Image src={EngageThree} alt='engage' className='engageThree' />
                  </div>
                </ImagesContainer>
              </FlexBox>
                )
              }
            </Container>
          </Section>
          {/* End of employee engagement */}
    
          {/* Partnership */}
          <Section className='partnership'>
            <Container className='partnership'>
              {/* {
                mobile ? (
                  <h1>Working on it...</h1>
                ) : (
                  <> */}
              <Header className='partnership'>
                Environmental Footprint and Partnerships
              </Header>
              <FlexBox className='partnership'>
                <Blog>
                <Carousel 
                enableAutoPlay={true} 
                pagination={false}
                ref={carouselRef}
                isRTL={false}
                autoPlaySpeed={4000}
                onNextEnd={() => {
                  clearTimeout(resetTimeout);
                  resetTimeout = setTimeout(() => {
                      carouselRef?.current?.goTo(0);
                  }, 4000);
                }}>
                  {
                    footprintState?.data?.map(item => {
                    return <Card 
                    className='partnership' 
                    key={item?.imageUrl}>
                      <Image
                        src={item?.imageUrl}
                        alt='sustainability'
                        className='partnership'
                      />
                      <CardBody>
                        <p>{item?.title}</p>
                        <CardLink 
                        to={item?.link} 
                        target='_blank' 
                        rel='noreferrer'
                      >
                          Learn More
                        </CardLink>
                      </CardBody>
                    </Card>
                    })
                  }
                  </Carousel>
                </Blog>
                <Content className='partnership'>
                  <p>
                    Over time, Abbey has partnered with
                    various organizations including the
                    Nigerian Red Cross.
                  </p>
                  <ImageWrapper>
                    <Image src={RedCross} alt='red-cross' className='icon' />
                    <Image
                      src={DownSyndrome}
                      alt='down-syndrome'
                      className='icon'
                    />
                    <Image src={Nhil} alt='nhil' className='icon' />
                    <Image src={Lagos} alt='lagos' className='icon' />
                  </ImageWrapper>
                </Content>
              </FlexBox>     
                  {/* </>
                )
              } */}
            </Container>
          </Section>
          {/* End of Partnership */}
          {/* footer */}
          {/* <Footer /> */}
          {/* End of footer */}
          </>
        )
      }
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Glass = styled.div`
  width: 540px;
  height: 250px;
  background: rgba(255, 255, 255, 0.7);
  ${Screen.mediumpc`
  height: 230px;
  `}
  ${Screen.tablet`
  height: 235px;
  `}
  ${Screen.surfaceDuo`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.phone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.smallPhone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
`;

const Content = styled.div`
  &.glass {
    padding: 3rem;
    ${Screen.surfaceDuo`
    padding: 0 1rem 1rem;
    `}
    ${Screen.mediumPhone`
    padding: 0 1rem 1rem;
    `}
    ${Screen.phone`
    padding: 0 1rem 1rem;
    `}
    ${Screen.smallPhone`
    padding: 0 1rem 1rem;
    `}
    h2 {
      font-size: 45px;
      color: #002668;
      text-shadow: 5px 3px 5px #666;
      ${Screen.mediumpc`
      font-size: 30px;
      padding-top: 0rem;
      `}
       ${Screen.smallpc`
      font-size: 30px;
      padding-top: 0;
      `}
      ${Screen.tablet`
      font-size: 30px;
      padding-top: 0;
      `}
      ${Screen.surfaceDuo`
      padding: 1rem 0 .5rem!important;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.mediumPhone`
      padding: 1rem 0 .5rem!important;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.phone`
      padding: 1rem 0 .5rem!important;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.smallPhone`
      padding: 1rem 0 .5rem!important;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.galaxyFold`
      padding: 1rem 0 .5rem!important;
      font-size: 22px;
      text-align: center;
      `}
    }

    p {
      color: #002668;
      font-size: 1.2rem;
      padding-top: 1rem;
      text-shadow: 5px 3px 5px #ccc;
      ${Screen.mediumpc`
      color: #002668;
      text-shadow: none;
      `}
      ${Screen.smallpc`
      color: #002668;
      text-shadow: none;
      font-size: 18px;
      padding-top: 2rem
      `}
      ${Screen.tablet`
        color: #002668;
      text-shadow: none;
      font-size: 16px;
      padding-top: 2rem
      `}
      ${Screen.surfaceDuo`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding-top: 1rem;
      `}
      ${Screen.mediumPhone`
       font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      `}
      ${Screen.phone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      `}
      ${Screen.smallPhone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      `}
    }
  }

  &.engagement {
    margin-right: 5rem;
    margin-top: 5rem;
    ${Screen.mediumpc`
    margin-right: 1rem;
    margin-top: 2rem;
    `}
    ${Screen.smallpc`
    margin-right: 1rem;
    margin-top: 2rem;
    `}
    ${Screen.tablet`
    margin-right: 1rem;
    margin-top: -1rem;
    `}
    ${Screen.surfaceDuo`
    margin-right: 0;
    margin-top: -3rem;
    `}
    ${Screen.mediumPhone`
    margin-right: 0;
    margin-top: -1rem;
    `}
    ${Screen.phone`
    margin-right: 0;
    margin-top: -1rem;
    `}
    ${Screen.smallPhone`
    margin-right: 0;
    margin-top: -1rem;
    `}
    p {
      padding-top: 2rem;
      font-size: 1.1rem;
      word-spacing: 0.1rem;
      ${Screen.mediumpc`
      font-size: 1.05rem;
      word-spacing: 0;
      // padding-top: 1rem;
      `}
      ${Screen.smallpc`
      font-size: .9rem;
      word-spacing: 0;
      padding-top: 1rem;
      `}
      ${Screen.mediumPhone`
      font-size: 14px;
      word-spacing: 0;
      padding-top: 1rem;
      `}
      ${Screen.phone`
      font-size: 14px;
      word-spacing: 0;
      padding-top: 1rem;
      `}
      ${Screen.samsungGalaxy`
      font-size: 13px;
      word-spacing: 0;
      padding-top: 1rem;
      `}
      ${Screen.smallPhone`
      font-size: 12px;
      word-spacing: 0;
      padding-top: 1rem;
      `}
    }
  }

  &.partnership {
    ${Screen.miniLargeScreen`
    padding-top: 0;
    width: 45%;
    `}
    ${Screen.pad`
    padding-top: 0;
    width: 45%;
    `}
    ${Screen.mediumpc`
    padding-top: 0;
    width: 45%;
    `}
    ${Screen.smallpc`
    padding-top: 0;
    width: 45%;
    `}
    ${Screen.surfaceDuo`
    width: 100%;
    padding-top: 3rem;
    `}
    ${Screen.mediumPhone`
    padding-top: 3rem;
    `}
    ${Screen.phone`
    padding-top: 3rem;
    `}
    ${Screen.smallPhone`
    padding-top: 3rem;
    `}
    p {
      text-align: right;
      font-size: 1.1rem;

      ${Screen.smallpc`
      text-align: right;
      font-size: 1rem;
      `}
      ${Screen.surfaceDuo`
      text-align: center;
      font-size: 1rem;
      `}
      ${Screen.mediumPhone`
      text-align: center;
      font-size: 1rem;
      `}
      ${Screen.phone`
      text-align: center;
      font-size: 1rem;
      `}
      ${Screen.smallPhone`
      text-align: center;
      font-size: .9rem;
      `}
    }
  }
`;
const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  &.hero {
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    width: 85%;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.mediumpc`
    padding-top: 7rem;
    `}
     ${Screen.tablet`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
  }
  /* ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.tablet`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.phone`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 3rem auto!important;
  `} */
  
  &.focus {
    ${Screen.miniLargeScreen`
  width: 85%;
  margin: 3rem auto;
  `}
    ${Screen.pad`
  width: 90%;
  margin: 3rem auto;
  `}
    ${Screen.mediumpc`
  width: 90%;
  margin: 3rem auto;
  `}
    ${Screen.mediumPhone`
  width: 90%;
  margin: 3rem auto;
  `}
    ${Screen.phone`
  width: 90%;
  margin: 3rem auto;
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 3rem auto;
  `}
  ${Screen.galaxyFold`
  width: 95%;
  margin: 3rem auto;
  `}
  }

  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 1rem auto;
  `}
  ${Screen.pad`
  width: 90%;
  margin: 1rem auto;
  `}
  ${Screen.mediumpc`
  width: 90%;
  margin: 1rem auto;
  `}
  ${Screen.smallpc`
  width: 90%;
  margin: 2rem auto;
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 2rem auto;
  `}
  &.partnership {
    ${Screen.miniLargeScreen`
    width: 80%;
    `}
     ${Screen.mediumpc`
    width: 90%;
    `}
    ${Screen.smallpc`
    width: 90%;
    `}
    ${Screen.tablet`
    width: 90%;
    `}
    ${Screen.smallPhone`
    width: 90%;
    `}
  }

`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;

  &.partnership {
    margin-top: 5rem;
    ${Screen.miniLargeScreen`
      flex-direction: row;
      justify-content: space-around!important;
      align-items: center;
    `}
    ${Screen.pad`
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `}
    ${Screen.smallpc`
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `}
    ${Screen.miniTablet`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    `}
  }
  ${Screen.smallpc`
  flex-direction: row-reverse;
  `}
  ${Screen.surfaceDuo`
  flex-direction: column;
  `}
`;
const Image = styled.img`
  &.sustainability {
    width: 35rem;
    height: 20rem;
  }
  &.environment {
    width: 15rem;
    height: 15rem;
    margin: 0 auto;
  }
  &.engageOne {
    width: 260px;
    height: 320px;
    margin-top: 3rem;
    margin-right: 1rem;
    ${Screen.miniLargeScreen`
    width: 250px;
    height: 300px;
    margin-top: 0;
    margin-right: .5rem;
    `}
    ${Screen.pad`
    width: 200px;
    height: 250px;
    margin-top: 0;
    margin-right: .5rem;
    `}
    ${Screen.mediumpc`
    width: 200px;
    height: 250px;
    margin-top: 0;
    margin-right: .5rem;
    `}
    ${Screen.smallpc`
    width: 150px;
    height: 200px;
    margin-top: 0;
    margin-right: .5rem;
    `}
    ${Screen.mediumPhone`
    width: 127px;
    height: 153px;
    margin-top: 0;
    margin-right: .5rem;
    `}
    ${Screen.surfaceDuo`
    width: 100px;
    height: 120px;
    margin-top: 0;
    margin-right: .5rem;
    `}
    ${Screen.phone`
    width: 100px;
    height: 120px;
    margin-top: 0;
    margin-right: .5rem;
    `}
    ${Screen.smallPhone`
    width: 100px;
    height: 120px;
    margin-top: 0;
    margin-right: .5rem;
    `}
  }
  &.engageTwo {
    width: 180px;
    height: 220px;
    ${Screen.miniLargeScreen`
     width: 150px;
    height: 170px;
    `}
    ${Screen.pad`
     width: 100px;
    height: 120px;
    `}
    ${Screen.mediumpc`
     width: 100px;
    height: 120px;
    `}
    ${Screen.smallpc`
     width: 88px;
    height: 106px;
    `}
    ${Screen.surfaceDuo`
    width: 88px;
    height: 106px;
    `}
    ${Screen.mediumPhone`
    width: 88px;
    height: 106px;
    `}
    ${Screen.phone`
    width: 70px;
    height: 70px;
    `}
    ${Screen.smallPhone`
    width: 70px;
    height: 70px;
    `}
  }
  &.engageThree {
    width: 240px;
    height: 280px;
    margin-top: 1rem;
    ${Screen.miniLargeScreen`
    width: 180px;
    height: 230px;
    margin-top: .5rem;
    `}
    ${Screen.pad`
    width: 130px;
    height: 150px;
    margin-top: .5rem;
    `}
    ${Screen.mediumpc`
    width: 130px;
    height: 150px;
    margin-top: .5rem;
    `}
    ${Screen.smallpc`
    width: 117px;
    height: 136px;
    margin-top: .5rem;
    `}
     ${Screen.surfaceDuo`
    width: 117px;
    height: 136px;
    margin-top: .5rem;
    `}
    ${Screen.mediumPhone`
    width: 117px;
    height: 136px;
    margin-top: .5rem;
    `}
    ${Screen.phone`
    width: 90px;
    height: 100px;
    margin-top: .5rem;
    `}
    ${Screen.smallPhone`
    width: 90px;
    height: 100px;
    margin-top: .5rem;
    `}
  }
  &.partnership {
    width: 100%;
    height: 200px;
  }
  &.icon {
    width: 150px;
    height: 100px;
    margin-bottom: 0.5rem;
    ${Screen.mediumpc`
    width: 130px;
    height: 100px
    `}
    ${Screen.smallpc`
    width: 100px;
    height: 80px
    `}
    ${Screen.mediumPhone`
    width: 110px;
    height: 80px
    `}
    ${Screen.phone`
    width: 100px;
    height: 80px
    `}
    ${Screen.smallPhone`
    width: 100px;
    height: 80px
    `}
  }
`;
const ImagesContainer = styled.div`
  width: 60%;
  display: flex;
  ${Screen.miniLargeScreen`
  width: 40%;
  justify-content: flex-end
  `}
  ${Screen.pad`
  width: 40%;
  justify-content: flex-end
  `}
  ${Screen.mediumpc`
  width: 40%;
  justify-content: flex-end
  `}
   ${Screen.smallpc`
  width: 50%;
  justify-content: flex-end
  `}
  ${Screen.tablet`
  width: 100%;
  justify-content: flex-end
  `}
`;

const Section = styled.section`
  width: 100%;
  min-height: 100vh;
  &.hero {
    /* background-image: url(${Sustain}); */
    background-image: ${({ bg }) => bg};
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    padding-top: 13rem;
    ${Screen.mediumpc`
    min-height: 50vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }
  &.focus {
    /* ${Screen.pad`
    min-height: 60vh
    `} */
    ${Screen.mediumpc`
    min-height: 60vh
    `}
  }
  &.engagement {
    min-height: 80vh;
    ${Screen.miniLargeScreen`
    min-height: 70vh;
    padding-top: 5rem;
    `}
    ${Screen.pad`
    min-height: 100vh;
    padding-top: 5rem;
    `}
    ${Screen.mediumpc`
    min-height: 30vh;
    padding-top: 1rem;
    `}
    ${Screen.smallpc`
    min-height: 35vh;
    padding-top: 1rem;
    `}
    ${Screen.tablet`
    min-height: 40vh;
    padding-top: 5rem;
    `}
    ${Screen.surfaceDuo`
    min-height: 60vh;
    padding-top: 1rem;
    `}
    ${Screen.mediumPhone`
    min-height: 60vh;
    padding-top: 1rem;
    `}
     ${Screen.iphone`
    min-height: 60vh;
    padding-top: 1rem;
    `}
    ${Screen.iphone12`
    min-height: 65vh;
    padding-top: 1rem;
    `}
    ${Screen.phone`
    min-height: 70vh;
    padding-top: 1rem;
    `}
     ${Screen.samsungGalaxy`
    min-height: 65vh;
    padding-top: 1rem;
    `}
    ${Screen.smallPhone`
    min-height: 70vh;
    padding-top: 1rem;
    `}
  }
  &.partnership {
    background-color: #f5f5f5;
    ${Screen.mediumpc`
    min-height: 50vh
    `}
    ${Screen.tablet`
    min-height: 60vh
    `}
  }
`;
const Header = styled.h2`
  color: #002668;
  font-size: 2rem;
  &.focus {
    padding-top: 7rem;
    text-align: center;
    ${Screen.pad`
    padding-top: 5rem;
    `}
    ${Screen.surfaceDuo`
    padding-top: 2rem;
    font-size: 1.5rem
    `}
    ${Screen.mediumPhone`
    padding-top: 2rem;
    font-size: 1.3rem
    `}
    ${Screen.phone`
    padding-top: 2rem;
    font-size: 1.2rem
    `}
    ${Screen.smallPhone`
    padding-top: 1rem;
    font-size: 1.1rem
    `}
     ${Screen.galaxyFold`
    padding-top: 1rem;
    font-size: 1rem
    `}
  }
  &.partnership {
    text-align: center;
    padding-top: 5rem;

    ${Screen.smallpc`
    font-size: 1.4rem;
    padding-top: 3rem;
    `}
    ${Screen.mediumPhone`
    font-size: 20px;
    padding-top: 3rem;
    `}
    ${Screen.phone`
    font-size: 18px;
    padding-top: 3rem;
    `}
    ${Screen.smallPhone`
    font-size: 18px;
    padding-top: 3rem;
    `}
  }
  &.engagement {
    ${Screen.mediumpc`
    font-size: 1rem!important;
    padding-top: 0;
    `}
    ${Screen.smallpc`
    font-size: 1.5rem;
    padding-top: 0;
    `}
    ${Screen.tablet`
    font-size: 1.5rem;
    padding-top: 2rem;
    `}
    ${Screen.surfaceDuo`
    font-size: 1.5rem;
    padding-top: 0!imprtant;
    `}
    ${Screen.mediumPhone`
    font-size: 18px;
    padding-top: 0;
    `}
    ${Screen.phone`
    font-size: 18px;
    padding-top: 0;
    `}
    ${Screen.samsungGalaxy`
    font-size: 16px;
    padding-top: 0;
    `}
    ${Screen.smallPhone`
    font-size: 15px;
    padding-top: 0;
    `}
    ${Screen.galaxyFold`
    font-size: 13px!important;
    padding-top: 0;
    `}
  }
`;
const Blog = styled.div`
  width: 45%;
  ${Screen.mediumpc`
  width: 45%;
  `}
  ${Screen.smallpc`
  width: 50%;
  `}
  ${Screen.surfaceDuo`
  width: 100%;
  `}
`;
const ImageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 3rem;

  ${Screen.miniLargeScreen`
  margin-left: 4rem;
  `}
  ${Screen.pad`
  margin-left: 3rem;
  `}
  ${Screen.mediumpc`
  margin-left: 3rem;
  `}
  ${Screen.smallpc`
  margin-left: 3rem;
  `}
  ${Screen.surfaceDuo`
  align-items: center;
  justify-content: center;
  margin-left: 5rem;
  margin-bottom: 3rem;
  `}
  ${Screen.mediumPhone`
  align-items: center;
  justify-content: center;
  margin-left: 3rem;
  margin-bottom: 3rem
  `}
  ${Screen.phone`
  align-items: center;
  justify-content: center;
  margin-left: 3rem;
  margin-bottom: 3rem
  `}
  ${Screen.smallPhone`
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
  margin-bottom: 3rem
  `}
  ${Screen.galaxyFold`
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  margin-bottom: 3rem
  `}
`;
const Card = styled.div`
  &.focus {
    width: 300px;
    height: 400px;
    box-shadow: 1px 3px 10px #ccc, -1px -1px 1px #ccc;
    padding-top: 5rem;
    ${Screen.mediumpc`
  width: 300px!important;
  margin-right: 1rem;
  box-shadow: 1px 3px 10px #ccc, -1px -1px 1px #ccc;
  `}
  ${Screen.smallpc`
  width: 300px!important;
  height: 350px;
  padding-top: 3rem;
  box-shadow: 1px 3px 10px #ccc, -1px -1px 1px #ccc;
  `}
   ${Screen.tablet`
  width: 300px!important;
  box-shadow: 1px 3px 10px #ccc, -1px -1px 1px #ccc;
  `}
   ${Screen.surfaceDuo`
  width: 300px!important;
  box-shadow: 1px 3px 10px #ccc, -1px -1px 1px #ccc;
  `}
  ${Screen.mediumPhone`
   width: 250px!important;
  height: 300px;
  padding-top: 1rem;
  box-shadow: 1px 3px 10px #ccc, -1px -1px 1px #ccc;
  `}
  ${Screen.phone`
  width: 230px!important;
  height: 300px;
  padding-top: 1rem;
  box-shadow: 1px 3px 10px #ccc, -1px -1px 1px #ccc;
  `}
  ${Screen.smallPhone`
  width: 200px!important;
  height: 300px;
  padding-top: 1rem;
  box-shadow: 1px 3px 10px #ccc, -1px -1px 1px #ccc;
  `}
    ${Screen.galaxyFold`
  width: 180px!important;
  height: 320px;
  padding-top: 1rem;
  box-shadow: 1px 3px 10px #ccc, -1px -1px 1px #ccc;
  `}
  }
  &.partnership {
    width: 21rem;
    height: auto;
    background-color: #f5f5f5;
    margin-right: 2rem;
    box-shadow: 3px 3px 1px #ccc, -3px -1px 5px #ccc;
    border-bottom: 3px solid #ccc;

    ${Screen.miniLargeScreen`
    width: 20rem;
    margin-right: 2rem;
    `}
    ${Screen.pad`
    width: 19rem;
    margin-right: 2rem;
    `}
    ${Screen.mediumpc`
    width: 19rem;
    margin-right: 2rem;
    `}
    ${Screen.smallpc`
    width: 19rem;
    margin-right: 2rem;
    `}
    ${Screen.surfaceDuo`
    width: 20rem;
    margin: -2rem auto 0;
    `}
    ${Screen.mediumPhone`
    width: 18rem;
    margin: -2rem auto 0;
    `}
    ${Screen.phone`
    width: 18rem;
    margin: -2rem auto 0;
    `}
    ${Screen.smallPhone`
    width: 17rem;
    margin: -2rem auto 0;
    `}
    ${Screen.galaxyFold`
    width: 16rem;
    margin: -2rem auto 0;
    `}
  }
`;
const CardBody = styled.div`
  padding: 2rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-weight: bold;
    padding-bottom: 2rem;
    ${Screen.pad`
  font-size: .95rem;
  `}
   ${Screen.mediumpc`
  font-size: 1rem;
  `}
  ${Screen.surfaceDuo`
  font-size: 1rem;
  `}
  ${Screen.mediumPhone`
  font-size: .9rem;
  `}
  ${Screen.phone`
  font-size: .9rem;
  `}
  ${Screen.smallPhone`
  font-size: .9rem;
  `}
  ${Screen.galaxyFold`
  font-size: .8rem;
  `}
  }
  ${Screen.pad`
  padding: 2rem 1rem 1rem;
  `}
  ${Screen.mediumpc`
  padding: 2rem .5rem 1rem;
  `}
  ${Screen.surfaceDuo`
  padding: 2rem 1rem 1rem;
  `}
  ${Screen.mediumPhone`
  padding: 2rem 1rem 1rem;
  `}
  ${Screen.phone`
  padding: 2rem 1rem 1rem;
  `}
  ${Screen.smallPhone`
  padding: 2rem .7rem 1rem;
  `}
  ${Screen.galaxyFold`
  padding: 2rem 1rem 1rem;
  `}
`;
const CardLink = styled(Link)`
  color: #c81e19;
  font-size: 0.9rem;
  font-weight: bold;
  padding-bottom: 1rem;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
`;
const CardHeader = styled.h5`
  /* font-size: 30px; */
  font-weight: bold;
  color: #c81e19;
  text-align: center;
  font-size: 1.3rem;
  padding-top: 1rem;
`;
const CardContent = styled.div`
  margin-top: 1rem;

  p {
    padding: 0.5rem 2rem 0;
    font-size: 0.9rem;

    ${Screen.mediumpc`
    padding: 1rem;
    `}
    ${Screen.phone`
    padding: 1rem;
    `}
    ${Screen.smallPhone`
    padding: 1rem;
    `}
  }
`;

const SubHeader = styled.article`
  text-align: center;
  color: #002668;
  padding-top: 1rem;
  font-size: 1.3rem;

  ${Screen.mediumpc`
    margin-bottom: 5rem
  `}
  ${Screen.smallpc`
    margin-bottom: 5rem
  `}
  ${Screen.mediumPhone`
    font-size: 1rem;
    margin-bottom: 5rem
  `}
  ${Screen.phone`
    font-size: 1rem;
    margin-bottom: 5rem
  `}
  ${Screen.smallPhone`
    font-size: 1rem;
    margin-bottom: 5rem
  `}
  ${Screen.smallPhone`
    font-size: .9rem;
    margin-bottom: 5rem
  `}
`
export default Sustainability;
